<template>
  <layout-blank>
    <v-navigation-drawer
      ref="layoutDrawer"
      v-model="isVerticalNavMenuActive"
      app
      mini-variant-width="68"
      width="260"
      class="app-navigation-menu"
    >
      <vertical-nav-menu
        :nav-menu-items="navMenuItems"
        @close-nav-menu="isVerticalNavMenuActive = false"
      />
    </v-navigation-drawer>

    <v-row
      align="center"
      class="sm:tw-pt-1"
    >
      <v-col
        class="justify-start d-flex"
        cols="9"
        md="9"
        sm="9"
      >
        <!-- Slot: Navbar -->
        <div
          class="navbar-content-container"
        >
          <div
            v-if="!isVerticalNavMenuActive"
            class="d-flex align-center"
          >
            <v-icon
              class="me-3"
              @click="toggleVerticalNavMenuActive"
            >
              {{ icons.mdiMenu }}
            </v-icon>
            <!-- <v-icon
              v-if="route.name !== 'home'"
              class="me-3"
              color="primary"
              @click="goHome"
            >
              {{ icons.mdiHomeCircle }}
            </v-icon> -->

            <form-header
              :single-item="comboOptions.singleItem"
              :form-drug-table="comboOptions.formDrugTable"
              :form-doc-table="comboOptions.formDocTable"
              :form-title="comboOptions.formTitle"
              :breadcrumbs="breadcrumbs"
            >
            </form-header>
            <app-bar-filter></app-bar-filter>
          </div>
        </div>
      </v-col>
      <v-col
        class="justify-end d-flex tw-pr-6"
        cols="3"
        md="3"
        sm="3"
        xs="3"
      >
        <app-bar-theme-switcher></app-bar-theme-switcher>
        <app-bar-user-menu></app-bar-user-menu>
      </v-col>
    </v-row>
    <slot></slot>
  </layout-blank>
</template>

<script>
import { mdiPill, mdiMenu, mdiHomeCircle } from '@mdi/js'
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import VerticalNavMenu from '@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import { ref, watch } from 'vue'
import navMenuItems from '@/navigation/vertical'
import { useRouter, useRoute } from 'vue-router/composables'
import AppBarFilter from '@/components/AppBar/AppBarFilter.vue'

export default {
    components: {
        LayoutBlank,
        AppBarUserMenu: () => import('@/components/AppBar/AppBarUserMenu.vue'),
        AppBarThemeSwitcher: () => import(
            '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
        ),
        formHeader: () => import('@/components/header/formHeader.vue'),
        basicHeader: () => import('@/components/header/basicHeader.vue'),
        VerticalNavMenu,
        AppBarFilter,
    },
    setup() {
        const router = useRouter()
        const route = useRoute()
        const isVerticalNavMenuActive = ref(false)
        const toggleVerticalNavMenuActive = () => {
            isVerticalNavMenuActive.value = !isVerticalNavMenuActive.value
        }
        const goHome = () => {
            if (route.name === 'home') return

            router.push({ name: 'home' })
        }

        return {
            route,
            router,
            goHome,
            navMenuItems,
            toggleVerticalNavMenuActive,
            isVerticalNavMenuActive,
            icons: {

                mdiPill,
                mdiMenu,
                mdiHomeCircle
            },
        }
    },
    data() {
        return {
            comboOptions: {
                singleItem: false,
                formDrugTable: false,
                formDocTable: false,
                formTitle: 'DocsRx',
            },
            breadcrumbs: [
                { title: 'Home', routeName: 'home' },
                { title: 'Patient Profile', routeName: 'patient-profile' },
                { title: 'Minor Ailment Hub', routeName: 'minor-ailments-hub' },
            ],
        }
    },
    watch: {
        $route: {
            handler() {
                this.comboOptions.formTitle = this.$route.meta.title
            },
            immediate: true,
            deep: true,
        },
    },
}
</script>
